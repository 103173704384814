<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">号外，号外，我们又和一所高校握手了！</div>
      <p class="time">2019.04.01</p>
      <div class="time" style="margin-top: -20px;color: black">地处滨海之滨，山明兮水秀，</div>
      <div class="time" style="margin-top: -20px;color: black">有着“中国最美校园”的厦大，</div>
      <div class="time" style="margin-top: -20px;color: black">红瓦白墙，绿草如茵，白鹭成群，</div>
      <div class="time" style="margin-top: -20px;color: black">“穿西装，戴斗笠”的嘉庚建筑，</div>
      <div class="time" style="margin-top: -20px;color: black">中西合璧，风格独树一帜，</div>
      <div class="time" style="margin-top: -20px;color: black">这就是近百年院校——<span style="font-weight: 600">“厦门大学”</span></div>
      <div class="time" style="margin-top: -20px;color: black">近日，我们与近百年的院校握手了！</div>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/23-1.png')">
        <p class="center_">（图为宋禹廷总裁代表
          <span style="font-weight: 600">
            厦门历思科技服务有限公司、福建历思司法鉴定所，
          </span>赵毅峰博士代表
          <span style="font-weight: 600">
            厦门大学信息科学与技术学院，
          </span>举行合作挂牌仪式）
        </p>
      </div>

      <p class="space_content" >
        福建历思司法鉴定所是国内司法鉴定管理体制改革后成立的第一批独立的第三方司法鉴定机构，是全国为数不多的拥有电子数据资质的鉴定机构。厦门历思科技服务有限公司一直致力于探索智慧物证科技，为司法鉴定行业提供了技术支持与发展动力。
      </p>
      <p class="space_content" >
        厦门大学是一所以培养应用型、复合型专业人才为培养目标的综合性研究型全国重点大学。厦门大学信息科学与技术学院（以下简称信息学院）是厦门大学二级学院。信息学院积极探索电子信息科学的教学模式及拓展专业实践教学基地，是培养电子信息科学与技术人才的专业实践平台。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/23-2.png')">
      </div>
      <p class="space_content" >
        目前，历思与西南政法大学、厦门大学、福州大学、集美大学、福建省环科院等多所高校和研究所开展了形式多样的合作，共同建了物证联合研究中心、联合实验室与教学科研实践基地。历思是西南政法大学在福建省唯一的教学科研实训基地，与西政早已联合技术研究、重大敏感案件讨论、专家互访、学生实习等实现常态化的交流。
      </p>
      <p class="space_content" >
        未来，历思将会寻求更多优质的智力资源加入到司法鉴定的队伍中来...
      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/22'"><p class="ellipsis1">朱墨时序鉴真伪，却是老赖“挖坑”埋了自己！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/24'"><p class="ellipsis1">庆贺，全项7大类一次性通过！根据环境损害鉴定新资质要求，历思顺利进行重新审核登记！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
